import { format } from "date-fns";
import * as XLSX from 'xlsx';
import { i18n } from '../../../translate/i18n';
import Table from "./tableRelatory";
import { calcularTempoAtendimento, calcularTempoEmDias } from "./tableRelatory";
import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";

// Estilos usando makeStyles para manter o código organizado
const useStyles = makeStyles((theme) => ({
  button: {
    padding: "7px",
    float: "right",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
	"&:hover": {
		backgroundColor: theme.palette.primary.main,
	},
    "& svg": {
		color: theme.palette.primary.contrastText,
		marginRight: '5px'
    },
  },
}));

const TableRelatory = ({ messagesRelatory, values, title, subtitle }) => {

  const classes = useStyles(); // Hook para acessar as classes de estilo

  const export_excel = () => {
    var tickets = [];

    messagesRelatory.forEach(ticket => {
      let splitName = ticket['whatsappName'].indexOf(' - ') ? ticket['whatsappName'].split(' - ') : ticket['whatsappName'];
      tickets.push({
        'Ticket': ticket['ticketId'],
        'Canal': ticket['moduleId'] === 2 ? "Instagram" : "WhatsApp",
        'Contato': ticket['Contact.isGroup'] == 1 ? i18n.t('groupPrefix') + ticket['Contact.name'] : ticket['Contact.name'],
        'Número': ticket['Contact.number'],
        'Conexão': Array.isArray(splitName) ? splitName.slice(0, -1).join(' - ') : splitName,
        'Número da conexão': Array.isArray(splitName) ? splitName[splitName.length - 1] : splitName,
        'Fila': ticket['Queue.name'],
        'Data Início': format((new Date((ticket['createdAt']))), "dd/MM/yyyy"),
        'Hora Início': format((new Date((ticket['createdAt']))), "HH:mm"),
        'Data Fim': ticket['status'] === 'closed' ? format((new Date((ticket['updatedAt']))), "dd/MM/yyyy") : '',
        'Hora Fim': ticket['status'] === 'closed' ? format((new Date((ticket['updatedAt']))), "HH:mm") : '',
        'Tempo de Atendimento': calcularTempoAtendimento(ticket['createdAt'], ticket['updatedAt']),
        'Qtde Dias Atendimento': calcularTempoEmDias(ticket['createdAt'], ticket['updatedAt']),
        'Usuário Atribuído': ticket['User.name'] || (ticket['isChatbot'] ? "Chatbot" : ""),
        'status': i18n.t(`relatoryTicket.status.${ticket['status']}`),
        'finalização': ticket['ConclusionMessage.shortcut'],
        'Motivo da finalização': ticket['lastReason'],
        'Finalizado por:': ticket['finishedBy'] || ((ticket['isChatbot'] && ticket['status'] == 'closed') ? "Chatbot" : ""),
      });
    });
    const ws = XLSX.utils.json_to_sheet(tickets)
    // Definindo a largura das colunas
    ws['!cols'] = [
      { wch: 10 }, // 'Ticket'
      { wch: 20 }, // 'Contato'
      { wch: 15 }, // 'Número'
      { wch: 20 }, // 'Conexão'
      { wch: 20 }, // 'Número da conexão'
      { wch: 15 }, // 'Fila'
      { wch: 15 }, // 'Data do início'
      { wch: 15 }, // 'Hora do inicio'
      { wch: 15 }, // 'Data do fim'
      { wch: 15 }, // 'Hora do fim'
      { wch: 20 }, // 'Tempo de Atendimento'
      { wch: 20 }, // 'Qtde Dias Atendimento'
      { wch: 20 }, // '(Usuário) Atribuído'
      { wch: 15 }, // 'status'
      { wch: 20 }, // 'finalização'
      { wch: 25 }, // 'Motivo da finalização'
      { wch: 20 }, // 'Finalizado por:'
    ];
    
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Responses')
    XLSX.writeFile(wb, `Relatorio_${format((new Date()), "dd/MM/yyyy")}.xlsx`)
  }
  return (

    <>

      <div className='col-xl-12' style={{ marginBottom: '40px' }}>
        <div className={`card ${'card-xl-stretch mb-xl-8'}`}>
          <div className='card-body p-0 d-flex justify-content-evenly flex-column overflow-hidden'>
            <div className='d-flex flex-stack flex-wrap px-9 pt-9 pb-3'>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div className='me-2'>
                  <span className='fw-bolder text-gray-800 d-block fs-3'>{title}</span>
                  <span className='text-gray-400 fw-bold'>{subtitle}</span>
                </div>
                <div>
                  <Button variant="contained" className={classes.button} onClick={e => export_excel(e)}>Exportar</Button>
                </div>
              </div>
              <div className={`fw-bolder fs-3 text-info`}></div>
            </div>
            <div className='mixed-widget-10-chart'>
              <Table
                messagesRelatory={messagesRelatory} />
            </div>
          </div>
        </div>
      </div></>)
}

export default TableRelatory;