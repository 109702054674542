import { Button, Grid, Table, TableCell, TableHead, TableRow, TableBody, Switch, IconButton } from "@material-ui/core";
import { useState } from "react";
import { i18n } from "../../../translate/i18n";
import OriginStoreModal from "../Modals/OriginStoreModal";
import { useReducer, useEffect, useContext } from "react";
import socket from "../../../hooks/useSocket";
import { AuthContext } from "../../../context/Auth/AuthContext";
import toastError from "../../../errors/toastError";
import api from "../../../services/api";
import { toast } from "react-toastify";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from "../../ConfirmationModal";
import TableRowSkeleton from "../../TableRowSkeleton";

const reducer = (state, action) => {
    if (action.type === "LOAD_ORIGINS") {
        const origins = action.payload;
        const newOrigins = [];

        origins.forEach((origin) => {
            const originIndex = state.findIndex((o) => o.id === origin.id);
            if (originIndex !== -1) {
                state[originIndex] = origin;
            } else {
                newOrigins.push(origin);
            }
        });

        return [...state, ...newOrigins];
    }

    if (action.type === "UPDATE_ORIGINS") {
        const origin = action.payload;
        const originIndex = state.findIndex((o) => o.id === origin.id);
        if (originIndex !== -1) {
            state[originIndex] = origin;
            return [...state];
        } else {
            return [origin, ...state];
        }
    }

    if (action.type === "DELETE_ORIGINS") {
        const originId = action.payload;

        const originIndex = state.findIndex((o) => o.id === originId);
        if (originIndex !== -1) {
            state.splice(originIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

export default function OriginTab({ data, setData }) {

    const [originModal, setOriginModal] = useState(false);
    const [origins, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const [selectedOrigin, setSelectedOrigin] = useState(null);
    const adminId = user.adminId;
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    useEffect(() => {
        socket.on(`customerRelationshipOrigin-${adminId}`, (data) => {

            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_ORIGINS", payload: data.origin });
            }

            if (data.action === "delete") {
                dispatch({
                    type: "DELETE_ORIGINS",
                    payload: +data.originId,
                });
            }
        });

        return () => { };
    }, []);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTags = async () => {
                try {
                    const { data } = await api.get("/customerRelationship/origin/list");
                    dispatch({ type: "LOAD_ORIGINS", payload: data.origins });
                    setLoading(false);
                } catch (err) {
                    console.error({ err });
                    toastError(err);
                }
            };
            fetchTags();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);


    const handleOriginModalClose = () => {
        setSelectedOrigin(null);
        setOriginModal(false);
    }

    const handleDisable = async (originData) => {
        try {
            setLoading(true);
            originData = { ...originData, status: !originData.status };
            await api.put(`/customerRelationship/origin/update/${originData.id}`, originData);
            toast.success(i18n.t('backendSuccess.SAVED_SUCCESSFULLY'));
            setLoading(false);
        } catch (err) {
            console.error({ err });
            toastError(err);
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await api.delete(`/customerRelationship/origin/delete/${selectedOrigin.id}`);
            toast.success(i18n.t('backendSuccess.DELETED_SUCCESSFULLY'));
            setLoading(false);
        } catch (err) {
            console.error({ err });
            toastError(err);
            setLoading(false);
        }
    }

    const handleConfirmModalClose = () => {
        setSelectedOrigin(null);
        setConfirmModalOpen(false);
    }

    return (
        <>
            <Grid container spacing={5}>
                <Grid item sm={12} xs={12} mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: 'right' }}
                        onClick={() => { setOriginModal(true) }}
                    >
                        {i18n.t('buttons.add')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={5}>
                <Grid item sm={12} xs={12} mb={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{i18n.t("customerRelationship.configuration.productAndService.table.name")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.productAndService.table.status")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.productAndService.table.editedBy")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.productAndService.table.actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading == true
                                    ?
                                    <TableRowSkeleton columns={4} />
                                    :
                                    origins.map((origin) => (
                                        <TableRow>
                                            <TableCell
                                                origin="th"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                            >{origin?.name || ''}</TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={origin.status}
                                                    onChange={() => handleDisable(origin)}
                                                    color="primary"
                                                    name={`status${origin.status}`}
                                                    key={`status${origin.status}`}
                                                    inputProps={{ 'aria-label': 'handle status' }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                            >
                                                {origin.user?.name || ''}
                                            </TableCell>
                                            <TableCell
                                                style={{ display: "flex" }}
                                            >
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => {
                                                        setSelectedOrigin(origin);
                                                        setOriginModal(true);
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="secondary"
                                                    component="span"
                                                    onClick={() => {
                                                        setSelectedOrigin(origin);
                                                        setConfirmModalOpen(true);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <OriginStoreModal
                open={originModal}
                onClose={handleOriginModalClose}
                originId={selectedOrigin?.id || undefined}
            />
            <ConfirmationModal
                title={i18n.t("customerRelationship.configuration.productAndService.delete.title")}
                open={confirmModalOpen}
                onClose={handleConfirmModalClose}
                onConfirm={handleDelete}
            >
                {i18n.t("customerRelationship.configuration.productAndService.delete.description")}
            </ConfirmationModal>
        </>
    );
}