import DialogTitle from "@material-ui/core/DialogTitle";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useContext, useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { KTSVG } from '../../../_metronic/helpers';
import ConfirmationModal from "../../components/ConfirmationModal";
import QuickAnswersModal from "../../components/QuickAnswersModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Box, Divider } from "@mui/material";

const drawerWidth = 320;
const reducer = (state, action) => {
	if (action.type === "LOAD_QUICK_ANSWERS") {
		const quickAnswers = action.payload;
		const newQuickAnswers = [];
		quickAnswers.forEach((quickAnswer) => {
			const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);
			if (quickAnswerIndex !== -1) {
				state[quickAnswerIndex] = quickAnswer;
			} else {
				newQuickAnswers.push(quickAnswer);
			}
		});
		return [...state, ...newQuickAnswers];
	}

	if (action.type === "UPDATE_QUICK_ANSWERS") {
		const quickAnswer = action.payload;
		const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);

		if (quickAnswerIndex !== -1) {
			state[quickAnswerIndex] = quickAnswer;
			return [...state];
		} else {
			return [quickAnswer, ...state];
		}
	}

	if (action.type === "DELETE_QUICK_ANSWERS") {
		const quickAnswerId = action.payload;

		const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswerId);
		if (quickAnswerIndex !== -1) {
			state.splice(quickAnswerIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useStyles = makeStyles(theme => ({
	drawer: {
		width: 0,
		flexShrink: 0,
		transitionDuration: "0.3s"
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#fff",
		flexDirection: "column",
		padding: "8px 0px 0px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	button100: {
		width: "270px",
		marginTop: "10px",
	},
	bottomContainer: {
		background: "#ffffff",
		width: "100%",
		padding: "20px 0",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		marginTop: "20px",
		position: "sticky",
		bottom: "0"
	}
}));

const ContactFlashMessenger = ({ open, handleDrawerClose, contact, ticketid, ticket }) => {
	const classes = useStyles();
	const [quickAnswers, dispatch] = useReducer(reducer, []);
	const { user } = useContext(AuthContext);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [deletingQuickAnswers, setDeletingQuickAnswers] = useState(null);
	const [quickAnswersModalOpen, setQuickAnswersModalOpen] = useState(false);
	const [selectedQuickAnswers, setSelectedQuickAnswers] = useState(null);
	const [quickAnswerIds, setQuickAnswerIds] = useState([]);

	useEffect(() => {

		if (open != true) return;

		const delayDebounceFn = setTimeout(() => {
			const fetchQuickAnswers = async () => {
				try {
					const { data } = await api.get("/quickAnswers/");
					dispatch({ type: "LOAD_QUICK_ANSWERS", payload: data.quickAnswers });
				} catch (err) {
					console.error({ err });
					toastError(err);
				}
			};
			fetchQuickAnswers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [open]);

	if (open) {
		if (window.innerWidth > 560) {
			document.querySelector("#ModalAutoMessege").style = "width: 320px !important;"
		}
	}

	function modalAutoMessegeClose(e) {
		document.querySelector("#ModalAutoMessege").style = "width: 0px !important;"
	}

	async function reloadPage() {
		setQuickAnswerIds([]);
		try {
			const { data } = await api.get("/quickAnswers/");

			dispatch({ type: "RESET" });

			dispatch({ type: "LOAD_QUICK_ANSWERS", payload: data.quickAnswers });

		} catch (err) {
			console.error({ err });
			toastError(err);
		}
	}

	const handleOpenQuickAnswersModal = () => {
		setSelectedQuickAnswers(null);
		setQuickAnswersModalOpen(true);
	};

	const handleCloseQuickAnswersModal = () => {
		reloadPage()
		setSelectedQuickAnswers(null);
		setQuickAnswersModalOpen(false);
	};

	const handleDeleteQuickAnswers = async (quickAnswerId) => {
		try {
			await api.delete(`/quickAnswers/${quickAnswerId}`);
			toast.success(i18n.t("quickAnswers.toasts.deleted"));
			reloadPage()
		} catch (err) {
			console.error({ err });
			toastError(err);
		}
		setDeletingQuickAnswers(null);
	};

	async function sendFlashMessage(data) {

		let { message } = data;

		if (message && message.trim() != '' && message != 'undefined') {
			if (user?.signature && user?.name) message = `*${user.name.trim()}:*\n` + message.trim();
			else message = message.trim();
		}

		if (data.media) {
			await sendMesseger({
				read: 1,
				fromMe: true,
				mediaInBody: data.media,
				body: null,
				cleanMsg: "",
				quotedMsg: null,
				moduleId: ticket.whatsapp.moduleId
			});
		} else if (data.isDrive && data.driveUrl) {
			await sendMesseger({
				read: 1,
				fromMe: true,
				mediaInBody: null,
				driveUrl: data.driveUrl,
				isDrive: data.isDrive,
				mediaName: data.mediaName,
				body: null,
				cleanMsg: "",
				quotedMsg: null,
				moduleId: ticket.whatsapp.moduleId
			});
		}

		if (data.message) {
			const msg = {
				read: 1,
				fromMe: true,
				mediaInBody: null,
				body: message,
				cleanMsg: "",
				quotedMsg: null,
				moduleId: ticket.whatsapp.moduleId
			};

			await sendMesseger(msg)
		}
	};

	async function sendMesseger(messageData) {
		try {

			if ((messageData.isDrive && messageData.driveUrl) || messageData.mediaInBody) {
				// resgatar informações do ticket e criar pré message
				const response = await api.post(`/messages/media/preMessage`, { ticketId: ticketid });
				if (response?.data?.messageUUID) messageData.messageUUID = response?.data?.messageUUID;
			}

			await api.post(`/messages/${ticketid}`, messageData);
		} catch (err) {
			console.error({ err });
			toastError(err);
		}
	}

	function returnSvg(texto) {
		var retorno = ""
		if (texto) {
			retorno = <div style={{ display: 'flex' }}><p style={{ margin: '0px' }}>{texto} / Arquivo <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/TK_archive_icon.svg/100px-TK_archive_icon.svg.png" style={{ width: '25px' }} /></p></div>

		} else {
			retorno = <div style={{ display: 'flex' }}><p style={{ margin: '0px' }}>Arquivo <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/TK_archive_icon.svg/100px-TK_archive_icon.svg.png" style={{ width: '25px' }} /></p></div>
		}
		return retorno;
	}

	const copyToClipboard = (str) => {
		const el = document.createElement("textarea");
		el.value = str;
		el.setAttribute("readonly", "");
		el.style.position = "absolute";
		el.style.left = "-9999px";
		document.body.appendChild(el);
		const selected =
			document.getSelection().rangeCount > 0
				? document.getSelection().getRangeAt(0)
				: false;
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
		if (selected) {
			document.getSelection().removeAllRanges();
			document.getSelection().addRange(selected);
		}
	};

	const handleSelectedAnswer = (answerId) => {
		let updatedAnswers;

		if (quickAnswerIds.includes(answerId)) {
			updatedAnswers = quickAnswerIds.filter(id => id !== answerId);
		} else {
			updatedAnswers = [...quickAnswerIds, answerId];
		}
		setQuickAnswerIds(updatedAnswers);
	}

	const sendMultiplesMessages = async () => {
		// resgatar respostas com base nos ids selecionados
		if (!quickAnswerIds || quickAnswerIds?.length == 0) return;

		const qaTmp = quickAnswers.filter((q) => quickAnswerIds.includes(q.id));

		if (!qaTmp || qaTmp?.length == 0) return;
		for await (const q of qaTmp) {
			await sendFlashMessage(q);
		}
		setQuickAnswerIds([]);
	}

	function renderizarRespostas(quickAnswer, ticket) {

		// Verificar se o `moduleId` é 2
		if (ticket?.whatsapp?.moduleId !== 2) {
			// Se não for 2, renderiza normalmente
			return (
				<>
					<ButtonWithSpinner
						className={classes.button100}
						style={{ width: "180px", justifyContent: "start" }}
						variant="contained"
						type="submit"
						color="primary"
						title={!quickAnswer.message ? "Arquivo" : quickAnswer.message}
						onClick={() => sendFlashMessage(quickAnswer)}
					>
						{quickAnswer.message && !quickAnswer.media
							? quickAnswer.shortcut
							: !quickAnswer.message && quickAnswer.media
								? returnSvg(quickAnswer.shortcut)
								: returnSvg(quickAnswer.shortcut)}
					</ButtonWithSpinner>
					{/* Botões adicionais */}
					{renderAdditionalButtons(quickAnswer)}
				</>
			);
		}

		// Função para extrair o mimetype
		const getMimeType = (quickAnswer) => {
			// Caso tenha uma URL, extrai a extensão e verifica o mimetype
			if (quickAnswer.driveUrl) {
				const extension = quickAnswer.driveUrl.split(".").pop(); // Extrai a extensão do arquivo
				const mimeTypes = {
					pdf: "application/pdf",
					png: "image/png",
					jpg: "image/jpeg",
					jpeg: "image/jpeg",
					mp4: "video/mp4",
				};
				return mimeTypes[extension] || "application/octet-stream"; // Retorna o mimetype ou genérico
			}

			// Caso tenha um Blob (base64), extrai o tipo do prefixo
			if (quickAnswer.media) {
				const base64Prefix = quickAnswer.media.split(";")[0]; // Pega o prefixo antes do ponto e vírgula
				const mimeType = base64Prefix.split(":")[1]; // Pega o tipo do MIME (ex: application/pdf)
				return mimeType || "application/octet-stream";
			}

			return "";
		};

		// Verificar o mimetype
		const mimeType = getMimeType(quickAnswer)
		const isPdf = mimeType === "application/pdf";

		return (
			<>
				<ButtonWithSpinner
					className={classes.button100}
					style={{ width: "180px", justifyContent: "start" }}
					variant="contained"
					type="submit"
					color="primary"
					title={!quickAnswer.message ? "Arquivo" : quickAnswer.message}
					onClick={() => sendFlashMessage(quickAnswer)}
					disabled={isPdf} // Desabilita o botão se for PDF
				>
					{quickAnswer.message && !quickAnswer.media
						? quickAnswer.shortcut
						: !quickAnswer.message && quickAnswer.media
							? returnSvg(quickAnswer.shortcut)
							: returnSvg(quickAnswer.shortcut)}
				</ButtonWithSpinner>
				{/* Botões adicionais */}
				{renderAdditionalButtons(quickAnswer, isPdf)}
			</>
		);
	}

	// Função para renderizar os botões adicionais
	const renderAdditionalButtons = (quickAnswer, isPdf) => {
		return (
			<>
				<button
					style={{
						marginTop: "10px",
						border: "1px solid #dbdbdb",
						marginLeft: "5px",
					}}
					title={i18n.t("buttons.delete")}
					className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
					onClick={(e) => {
						setConfirmModalOpen(true);
						setDeletingQuickAnswers(quickAnswer);
					}}
				>
					<KTSVG
						path="/media/icons/duotune/general/gen027.svg"
						className="svg-icon-3"
					/>
				</button>
				<button
					style={{
						marginTop: "10px",
						border: "1px solid #dbdbdb",
						marginLeft: "5px",
					}}
					title={i18n.t("buttons.copy")}
					className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
					disabled={isPdf} // Desabilita o botão se for PDF
					onClick={(e) => {
						if (!quickAnswer.message) {
							toast.warn(i18n.t("quickAnswers.toasts.cannotCopied"));
						} else {
							setTimeout(() => {
								copyToClipboard(
									!quickAnswer.message ? "Arquivo" : quickAnswer.message
								);
								toast.success(i18n.t("quickAnswers.toasts.copied"));
							}, 500);
						}
					}}
				>
					<KTSVG
						path="/media/icons/duotune/general/gen054.svg"
						className="svg-icon-3"
					/>
				</button>
			</>
		);
	};

	function renderizarRespostasAdmin(quickAnswer, ticket) {

		// Verificar se o `moduleId` é 2
		if (ticket?.whatsapp?.moduleId !== 2) {
			return (
				<>
					<ButtonWithSpinner
						className={classes.button100}
						variant="contained"
						type="submit"
						color="primary"
						style={{
							width: "220px",
							backgroundColor: "#8c8a91",
							justifyContent: "start",
						}}
						title={!quickAnswer.message ? "Arquivo" : quickAnswer.message}
						onClick={() => sendFlashMessage(quickAnswer)}
					>
						{quickAnswer.message && !quickAnswer.media
							? quickAnswer.shortcut
							: !quickAnswer.message && quickAnswer.media
								? returnSvg(quickAnswer.shortcut)
								: returnSvg(quickAnswer.shortcut)}
					</ButtonWithSpinner>
					{renderAdminButtons(quickAnswer)}
				</>
			);
		}

		// Função para extrair o mimetype
		const getMimeType = (quickAnswer) => {
			// Caso tenha uma URL, extrai a extensão e verifica o mimetype
			if (quickAnswer.driveUrl) {
				const extension = quickAnswer.driveUrl.split(".").pop(); // Extrai a extensão do arquivo
				const mimeTypes = {
					pdf: "application/pdf",
					png: "image/png",
					jpg: "image/jpeg",
					jpeg: "image/jpeg",
					mp4: "video/mp4",
				};
				return mimeTypes[extension] || "application/octet-stream"; // Retorna o mimetype ou genérico
			}

			// Caso tenha um Blob (base64), extrai o tipo do prefixo
			if (quickAnswer.media) {
				const base64Prefix = quickAnswer.media.split(";")[0]; // Pega o prefixo antes do ponto e vírgula
				const mimeType = base64Prefix.split(":")[1]; // Pega o tipo do MIME (ex: application/pdf)
				return mimeType || "application/octet-stream";
			}

			return "";
		};

		// Verificar o mimetype
		const mimeType = getMimeType(quickAnswer);
		const isPdf = mimeType === "application/pdf";

		return (
			<>
				<ButtonWithSpinner
					className={classes.button100}
					tooltip={isPdf ? "Arquivo não suportado" : ""}
					variant="contained"
					type="submit"
					color="primary"
					style={{
						width: "220px",
						backgroundColor: "#8c8a91",
						justifyContent: "start",
					}}
					title={!quickAnswer.message ? "Arquivo" : quickAnswer.message}
					onClick={() => sendFlashMessage(quickAnswer)}
					disabled={isPdf} // Desabilita o botão se for PDF
				>
					{quickAnswer.message && !quickAnswer.media
						? quickAnswer.shortcut
						: !quickAnswer.message && quickAnswer.media
							? returnSvg(quickAnswer.shortcut)
							: returnSvg(quickAnswer.shortcut)}
				</ButtonWithSpinner>
				{renderAdminButtons(quickAnswer, isPdf)}
			</>
		);
	}

	// Função para renderizar os botões adicionais
	const renderAdminButtons = (quickAnswer, isPdf) => {
		return (
			<button
				style={{
					marginTop: "10px",
					border: "1px solid #dbdbdb",
					marginLeft: "5px",
				}}
				title={i18n.t("buttons.copy")}
				className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
				disabled={isPdf} // Desabilita o botão se for PDF
				onClick={(e) => {
					if (!quickAnswer.message) {
						toast.warn(i18n.t("quickAnswers.toasts.cannotCopied"));
					} else {
						setTimeout(() => {
							copyToClipboard(
								!quickAnswer.message ? "Arquivo" : quickAnswer.message
							);
							toast.success(i18n.t("quickAnswers.toasts.copied"));
						}, 500);
					}
				}}
			>
				<KTSVG
					path="/media/icons/duotune/general/gen054.svg"
					className="svg-icon-3"
				/>
			</button>
		);
	};

	useEffect(() => {
		if (open) {
			document.addEventListener("mouseup", function (e) {
				var obj = document.getElementById("ModalAutoMessege");
				if (obj) {
					if (!obj.contains(e.target)) {
						handleDrawerClose(e);
						modalAutoMessegeClose(e);
					}
				}
			});
		}
	}, [open]);

	return (
		<>
			<ConfirmationModal
				title={
					deletingQuickAnswers &&
					`${i18n.t("quickAnswers.confirmationModal.deleteTitle")}`
				}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={() => handleDeleteQuickAnswers(deletingQuickAnswers.id)}
			>
				{i18n.t("quickAnswers.confirmationModal.deleteMessage")}
			</ConfirmationModal>
			<QuickAnswersModal
				style={{ width: 100 }}
				open={quickAnswersModalOpen}
				onClose={handleCloseQuickAnswersModal}
				aria-labelledby="form-dialog-title"
				quickAnswerId={selectedQuickAnswers && selectedQuickAnswers.id}
			></QuickAnswersModal>
			<Drawer
				className={classes.drawer}
				id="ModalAutoMessege"
				variant="persistent"
				anchor="right"
				open={open}
				PaperProps={{ style: { position: "absolute" } }}
				BackdropProps={{ style: { position: "absolute" } }}
				ModalProps={{
					container: document.getElementById("drawer-container"),
					style: { position: "absolute" },
				}}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.header}>
					<IconButton
						id="closeModalAutoMessege"
						onClick={(e) => {
							handleDrawerClose(e);
							modalAutoMessegeClose(e);
						}}>
						<CloseIcon />
					</IconButton>
					<Typography style={{ justifySelf: "center" }}>
						{i18n.t("contactDrawer.headerFlashMessage")}
					</Typography>
				</div>

				<div className={classes.content}>
					<Paper square variant="outlined" style={{ marginTop: '0', padding: '15px', border: 'none' }}>
						<DialogTitle id="form-dialog-title" style={{ textAlign: 'left' }}>
							{i18n.t("quickAnswers.contactMessenger.userShortcuts")}
						</DialogTitle>
						{quickAnswers.map((quickAnswer) => (
							<>
								{quickAnswer.adminId === user.adminId ?
									<>
										{quickAnswer.createdBy === user.id && quickAnswer.forAll === false ? renderizarRespostas(quickAnswer, ticket) : ''}
									</>
									: ''}
							</>
						))}

						<Divider style={{ marginTop: "20px" }} />

						<DialogTitle id="form-dialog-title" style={{ textAlign: 'left' }}>
							{i18n.t("quickAnswers.contactMessenger.systemShortcuts")}
						</DialogTitle>
						{quickAnswers.map((quickAnswer) => (
							<>
								{quickAnswer.adminId === user.adminId ?
									<>
										{quickAnswer.forAll === true ? renderizarRespostasAdmin(quickAnswer, ticket) : ''}
									</>
									: ''}
							</>
						))}

						<Box className={classes.bottomContainer}>
							<ButtonWithSpinner
								style={{ width: '100%', padding: '5px' }}
								variant="outlined"
								type="submit"
								color="primary"
								onClick={handleOpenQuickAnswersModal}
							>
								{i18n.t("quickAnswers.contactMessenger.add")}
							</ButtonWithSpinner>
						</Box>
					</Paper>
				</div>

			</Drawer>
		</>
	);
};

export default ContactFlashMessenger;
