import { TableCell } from "@material-ui/core";
import { Grid, Table, TableRow, TableHead, TableBody, Typography, CircularProgress, Tooltip } from "@mui/material";
import { Button } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import { useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import React, { useRef, useEffect, useState, useCallback, useReducer } from "react";
import toastError from "../../errors/toastError";
import { format, parseISO } from "date-fns";
import { CropFree, SignalCellular4Bar, SignalCellularConnectedNoInternet0Bar, SignalCellularConnectedNoInternet2Bar } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { KTSVG } from "../../../_metronic/helpers";
import ConfirmationModal from "../ConfirmationModal";
import QrcodeModal from "../QrcodeModal";
import WhatsAppModal from "./modal";
import { toast } from "react-toastify";
import socket from "../../hooks/useSocket";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: "center",
    },
    buttonProgress: {
        color: green[500],
    },
    modalStyle: {
        padding: '90px',
        minWidth: '310px',
        overflowY: 'scroll',
        "@media (max-width: 740px)": {
            padding: '0px !important',
            marginTop: '65px'
        }
    }
}));

const CustomToolTip = ({ title, content, children }) => {
    const classes = useStyles();

    return (
        <Tooltip
            arrow
            classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper,
            }}
            title={
                <React.Fragment>
                    <Typography gutterBottom color="inherit">
                        {title}
                    </Typography>
                    {content && <Typography>{content}</Typography>}
                </React.Fragment>
            }
        >
            {children}
        </Tooltip>
    );
};

const deletedConnections = [];

const deleteConnection = (whatsappId) => {
    if (whatsappId) {
        whatsappId = Number(whatsappId);
        if (deletedConnections.findIndex((item) => item == whatsappId) == -1) {
            deletedConnections.push(whatsappId);
        }
    }
};

const reducer = (state, action) => {
    if (action.type === "LOAD_WHATSAPPS") {
        const whatsapps = action.payload;
        const newWhatsapps = [];

        whatsapps.forEach((whatsapp) => {
            const whatsappIndex = state.findIndex((q) => q.id === whatsapp.id);
            if (whatsappIndex !== -1) {
                state[whatsappIndex] = whatsapp;
            } else {
                newWhatsapps.push(whatsapp);
            }
        });

        return [...state, ...newWhatsapps];
    }
  
    if (action.type === "UPDATE_WHATSAPPS") {
        const whatsapp = action.payload;
        const whatsappIndex = state.findIndex((q) => q.id == whatsapp.id);

        if (whatsappIndex !== -1) {
            state[whatsappIndex] = whatsapp;
            return [...state];
        } else {
            return [whatsapp, ...state];
        }
    }

    if (action.type === "DELETE_WHATSAPPS") {
        const whatsappId = action.payload;

        const whatsappIndex = state.findIndex((q) => q.id == whatsappId);

        if (whatsappIndex !== -1) {
            state.splice(whatsappIndex, 1);
        }
        deleteConnection(whatsappId);
        return [...state];
    }

    if (action.type === "RESET") {
      return [];
    }
  };

const WhatsAppTable = () => {

    const { user } = useContext(AuthContext);
    const isMounted = useRef(false);
    const classes = useStyles();
    const [qrModalOpen, setQrModalOpen] = useState(false);
    const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const confirmationModalInitialState = {
		action: "",
		title: "",
		message: "",
		whatsAppId: "",
		open: false,
	};
    const [confirmModalInfo, setConfirmModalInfo] = useState(confirmationModalInitialState);
    const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
    const [filteredWhatsApps, dispatch] = useReducer(reducer, []);


    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;

            const fetchWhatsapps = async () => {
                try {
                    const { data } = await api.get("/whatsappListAll", { params: { disabled: '1' } });
                    dispatch({ type: "LOAD_WHATSAPPS", payload: data.filter(w => w.state == 1) });
                    data.map(w => {
                        if (w.state == 0) deleteConnection(w.id);
                    })
                } catch (err) {
                    console.error({err});
                    toastError(err);
                }
            }

            fetchWhatsapps();
        }
    }, []);

    useEffect(() => {
        const loadFacebookSDK = () => {
                (function (d, s, id) {
                    let js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s); js.id = id;
                    js.src = "https://connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));

                window.fbAsyncInit = function () {
                    window.FB.init({
                        appId: '3735689559994297',
                        cookie: true,
                        xfbml: true,
                        version: 'v19.0'
                    });
                };
        };

        loadFacebookSDK();
    }, []);
  
    const responseFacebook = async (response) => {
    
        if (response.status === 'connected') {
    
            const accessToken = response.authResponse.accessToken;
    
            // Obter informações do usuário
            const userInfo = await new Promise((resolve) => {
                window.FB.api('/me', { fields: 'name,picture' }, resolve);
            });
    
            // Verifique o status de login para mais detalhes
            const statusResponse = await new Promise((resolve) => {
                window.FB.getLoginStatus(resolve);
            });
    
            if (statusResponse.status === 'connected') {
                const accessToken = statusResponse.authResponse.accessToken;
    
                // Obter as páginas
                const accountsResponse = await new Promise((resolve) => {
                    window.FB.api('/me/accounts', { access_token: accessToken }, resolve);
                });
    
                if (accountsResponse && !accountsResponse.error) {
                    const pages = accountsResponse.data;
    
                    // Pegue apenas a primeira página
                    if (pages.length > 0) {
                        const firstPage = pages[0];
    
                        // Obter o ID da conta do Instagram associada à página
                        const pageDetails = await new Promise((resolve) => {
                            window.FB.api(`/${firstPage.id}?fields=instagram_business_account`, { access_token: accessToken }, resolve);
                        });
    
                        const instagramAccount = pageDetails.instagram_business_account;
                        if (instagramAccount) {
                            
                            // Chamar a API para atualizar o token
                            await api.post("/whatsapp/updateInstaToken", {
                                accessToken: accessToken,
                                pageId: firstPage.id,
                                instagramId: instagramAccount.id,
                                pageName: firstPage.name
                            });
                        } else {
                            console.log(`A página ${firstPage.name} não está associada a uma conta do Instagram.`);
                        }
                    } else {
                        console.log("Nenhuma página encontrada.");
                    }
                } else {
                    console.error("Erro ao obter páginas:", accountsResponse.error);
                }
    
                console.log("Usuário agora está conectado:", statusResponse);
            } else {
                console.log("Usuário não está conectado ou não autorizou a aplicação.");
            }
        } else {
            console.log("Login falhou:", response);
    
            // Obter informações do usuário
            const userInfo = await new Promise((resolve) => {
                window.FB.api('/me', { fields: 'name,picture' }, resolve);
            });
            console.log(userInfo);
    
            // Verifique o status de login para mais detalhes
            const statusResponse = await new Promise((resolve) => {
                window.FB.getLoginStatus(resolve);
            });
    
            if (statusResponse.status === 'connected') {
                const accessToken = statusResponse.authResponse.accessToken;
    
                // Obter as páginas
                const accountsResponse = await new Promise((resolve) => {
                    window.FB.api('/me/accounts', { access_token: accessToken }, resolve);
                });
    
                if (accountsResponse && !accountsResponse.error) {
                    const pages = accountsResponse.data;
    
                    // Pegue apenas a primeira página
                    if (pages.length > 0) {
                        const firstPage = pages[0];
                        console.log(`ID da página: ${firstPage.id}, Nome: ${firstPage.name}`);
    
                        // Obter o ID da conta do Instagram associada à página
                        const pageDetails = await new Promise((resolve) => {
                            window.FB.api(`/${firstPage.id}?fields=instagram_business_account`, { access_token: accessToken }, resolve);
                        });
    
                        const instagramAccount = pageDetails.instagram_business_account;
                        if (instagramAccount) {
                            console.log(`ID da conta do Instagram: ${instagramAccount.id}`);
                            
                            // Chamar a API para atualizar o token
                            await api.post("/whatsapp/updateInstaToken", {
                                accessToken: accessToken,
                                pageId: firstPage.id,
                                instagramId: instagramAccount.id,
                                pageName: firstPage.name
                            });
                        } else {
                            console.log(`A página ${firstPage.name} não está associada a uma conta do Instagram.`);
                        }
                    } else {
                        console.log("Nenhuma página encontrada.");
                    }
                } else {
                    console.error("Erro ao obter páginas:", accountsResponse.error);
                }
    
                console.log("Usuário agora está conectado:", statusResponse);
            } else {
                console.log("Usuário não está conectado ou não autorizou a aplicação.");
            }
        }
    };

    useEffect(() => {

		socket.on(`whatsappSession-${user.adminId}`, (data) => {
			// verificar se veio com a flag de duplicado, para retornar ao usuário
			if (data?.action === "update" && data?.duplicated && data?.session?.id) {
				// verificar se a conexão está entre as filtradas
				let resp = filteredWhatsApps.filter(item => item.id == data.session.id);
				if (resp.length >= 1) { toast.error('Este número já possuí uma conexão no sistema.'); }
			}

			if (data?.action == "update") {
				const newWhatsapp = data.session;
                
				// verificar se a conexão já foi deletada
				if (newWhatsapp.state == false) {
                    dispatch({ type: "DELETE_WHATSAPPS", payload: newWhatsapp.id });
					return;
				}
				const deletedIndex = deletedConnections.findIndex((item) => (newWhatsapp.id == item));

				if (deletedIndex !== -1) {
                    dispatch({ type: "DELETE_WHATSAPPS", payload: newWhatsapp.id });
					return;
				}

                dispatch({ type: "UPDATE_WHATSAPPS", payload: newWhatsapp });
			}

			if (data?.action == "delete") {
				const whatsappId = data.session;
                dispatch({ type: "DELETE_WHATSAPPS", payload: whatsappId });
			}
		});
		return () => { };
	}, []);

    const renderStatusToolTips = whatsApp => {
        return (
            <div className={classes.customTableCell}>
                {whatsApp.status === "DISCONNECTED" && (
                    <CustomToolTip
                        title={i18n.t("connections.toolTips.disconnected.title")}
                        content={i18n.t("connections.toolTips.disconnected.content")}
                    >
                        <SignalCellularConnectedNoInternet0Bar color="secondary" />
                    </CustomToolTip>
                )}
                {whatsApp.status === "OPENING" && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
                {whatsApp.status === "qrcode" && (
                    <CustomToolTip
                        title={i18n.t("connections.toolTips.qrcode.title")}
                        content={i18n.t("connections.toolTips.qrcode.content")}
                    >
                        <CropFree />
                    </CustomToolTip>
                )}
                {whatsApp.status === "CONNECTED" && (
                    <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
                        <SignalCellular4Bar style={{ color: green[500] }} />
                    </CustomToolTip>
                )}
                {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
                    <CustomToolTip
                        title={i18n.t("connections.toolTips.timeout.title")}
                        content={i18n.t("connections.toolTips.timeout.content")}
                    >
                        <SignalCellularConnectedNoInternet2Bar color="secondary" />
                    </CustomToolTip>
                )}
            </div>
        );
    };

    const handleOpenConfirmationModal = (action, whatsAppId) => {
		if (action === "disconnect") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.disconnectTitle"),
				message: i18n.t("connections.confirmationModal.disconnectMessage"),
				whatsAppId: whatsAppId,
			});
		}

		if (action === "delete") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.deleteTitle"),
				message: i18n.t("connections.confirmationModal.deleteMessage"),
				whatsAppId: whatsAppId,
			});
		}

		setConfirmModalOpen(true);
	};

    const handleOpenQrModal = whatsApp => {
        setSelectedWhatsApp(whatsApp);
        setQrModalOpen(true);
    };

    const handleRequestNewQrCode = async whatsAppId => {
		try {
			await api.put(`/whatsappsession/${whatsAppId}`);
		} catch (err) {
            console.error({err});
			toastError(err);
		}
	};

    const handleSubmitConfirmationModal = async () => {
		if (confirmModalInfo.action === "disconnect") {
			try {
				await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
			} catch (err) {
                console.error({err});
				toastError(err);
			}
		}

		if (confirmModalInfo.action === "delete") {
			try {
				await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
				toast.success(i18n.t("connections.toasts.deleted"));
                // resetWpp();
			} catch (err) {
                console.error({err});
				toastError(err);
			}
		}

		setConfirmModalInfo(confirmationModalInitialState);
	};

    const renderActionButtons = whatsApp => {
        return (
            <>
                {whatsApp.status === "qrcode" && whatsApp.moduleId == "1" && (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenQrModal(whatsApp)}
                        style={{
                            borderColor: "#0B708C",
                            color: "#0B708C",
                            border: "1px solid",
                            background: "transparent"
                        }}
                    >
                        {i18n.t("connections.buttons.qrcode")}
                    </Button>
                )}
                {whatsApp.status === "DISCONNECTED" && whatsApp.moduleId == "1" && (
                    <>
                        {/* <Button
							size="small"
							variant="outlined"
							color="primary"
							onClick={() => handleStartWhatsAppSession(whatsApp.id)}
						>
							{i18n.t("connections.buttons.tryAgain")}
						</Button>{" "} */}
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleRequestNewQrCode(whatsApp.id)}
                            style={{
                                borderColor: "rgb(0, 168, 132)",
                                color: "rgb(0, 168, 132)"
                            }}
                        >
                            {i18n.t("connections.buttons.newQr")}
                        </Button>
                    </>
                )}
                {whatsApp.status === "DISCONNECTED" && whatsApp.moduleId == "2" && (
                    <>
                         <FacebookLogin
                            appId="3735689559994297" // Substitua pelo seu ID de aplicativo
                            autoLoad
                            scope="instagram_basic,pages_show_list,instagram_manage_messages,instagram_manage_insights,pages_manage_metadata,business_management"
                            callback={responseFacebook}
                            render={renderProps => (
                                <button 
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={renderProps.onClick}
                                    style={{
                                        borderColor: "rgb(0, 168, 132)",
                                        color: "rgb(0, 168, 132)",
                                        border: "1px solid",
                                        background: "transparent"
                                    }}>
                                        {i18n.t("connections.buttons.loginFacebook")}
                                </button>
                            )}
                        />
                    </>
                )}
                {(whatsApp.status === "CONNECTED" ||
                    whatsApp.status === "PAIRING" ||
                    whatsApp.status === "TIMEOUT") && (
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            style={{
                                borderColor: "#f50057",
                                color: "#f50057"
                            }}
                            onClick={() => {
                                handleOpenConfirmationModal("disconnect", whatsApp.id);
                            }}
                        >
                            {i18n.t("connections.buttons.disconnect")}
                        </Button>
                    )}
                {whatsApp.status === "OPENING" && whatsApp.moduleId == "1" && (
                    <Button size="small" variant="outlined" disabled color="default">
                        {i18n.t("connections.buttons.connecting")}
                    </Button>
                )}
            </>
        );
    };

	const handleCloseQrModal = useCallback(() => {
		setSelectedWhatsApp(null);
		setQrModalOpen(false);
	}, [setQrModalOpen, setSelectedWhatsApp]);

    const handleCloseWhatsAppModal = useCallback(() => {
		// resetWpp()
		setWhatsAppModalOpen(false);
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setWhatsAppModalOpen]);

    const handleEditWhatsApp = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setWhatsAppModalOpen(true);
	};

    return (
        <>
            <ConfirmationModal
                title={confirmModalInfo.title}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={handleSubmitConfirmationModal}
            >
                {confirmModalInfo.message}
            </ConfirmationModal>
            <QrcodeModal
                open={qrModalOpen}
                onClose={handleCloseQrModal}
                whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
            />
            <WhatsAppModal
                open={whatsAppModalOpen}
                onClose={handleCloseWhatsAppModal}
                whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
                userProfile={user.profile}
            />
            <Grid container spacing={5}>
                <Grid item sm={12} xs={12} mb={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{i18n.t("connections.table.channel")}</TableCell>
                                <TableCell>{i18n.t("connections.table.name")}</TableCell>
                                <TableCell>{i18n.t("connections.table.status")}</TableCell>
                                <TableCell>{i18n.t("connections.table.session")}</TableCell>
                                <TableCell>{i18n.t("connections.table.number")}</TableCell>
                                <TableCell>{i18n.t("connections.table.option.title")}</TableCell>
                                <TableCell>{i18n.t("connections.table.lastUpdate")}</TableCell>
                                <TableCell>{i18n.t("connections.table.lastDisconnection")}</TableCell>
                                <Can
                                    role={user.profile}
                                    perform="connections-page:editOrDeleteConnection"
                                    yes={() => (
                                        <TableCell>{i18n.t("connections.table.actions")}</TableCell>
                                    )}
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredWhatsApps && filteredWhatsApps.map((whatsApp) => (
                                <TableRow>
                                    <TableCell>{(whatsApp.moduleId == 2) ? "Instagram" : "WhatsApp"}</TableCell>
                                    <TableCell>{whatsApp.name}</TableCell>
                                    <TableCell>{renderStatusToolTips(whatsApp)}</TableCell>
                                    <TableCell>{renderActionButtons(whatsApp)}</TableCell>
                                    <TableCell>{whatsApp.numberConnection}</TableCell>
                                    <TableCell>{i18n.t(`connections.table.option.${whatsApp.option}`)}</TableCell>
                                    <TableCell>{format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}</TableCell>
                                    <TableCell>{(whatsApp.disconnectedAt) ? format(parseISO(whatsApp.disconnectedAt), "dd/MM/yy HH:mm") : "Não se aplica."}</TableCell>
                                    <Can
                                        role={user.profile}
                                        perform="connections-page:editOrDeleteConnection"
                                        yes={() => (
                                            <TableCell align="center" style={{padding: 0}}>
                                                <Button
                                                    title={i18n.t("buttons.edit")}
                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    onClick={() => handleEditWhatsApp(whatsApp)}
                                                >
                                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                </Button>
                                                <Button
                                                    title={i18n.t("buttons.delete")}
                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    onClick={e => {
                                                        handleOpenConfirmationModal("delete", whatsApp.id);
                                                    }}
                                                >
                                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                                </Button>
                                            </TableCell>
                                        )}
                                    />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </>
    )
}

export default WhatsAppTable;