import { Box, IconButton, Grid, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { i18n } from "../../../translate/i18n";
import { useReducer, useEffect, useContext } from "react";
import socket from "../../../hooks/useSocket";
import { AuthContext } from "../../../context/Auth/AuthContext";
import toastError from "../../../errors/toastError";
import api from "../../../services/api";
import { toast } from "react-toastify";
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from "@material-ui/core/styles";
import GetShort from "../../../helpers/GetShort";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const useStyles = makeStyles(theme => ({
    header: {
        position: 'relative',
        padding: '5px',
        width: '100%',
        justifyContent: 'start',
        alignItems: 'flex-end',
        display: 'flex',
        marginBottom: '10px',
        '&>span': {
            display: 'inline-block',
            height: '40px',
            width: '40px',
            background: '#0B708C',
            borderRadius: '100%',
            textAlign: 'center',
            paddingTop: '10px',
            color: '#ffffff',
        },
        '&>h6': {
            marginLeft: '5px',
            fontSize: '1.1rem',
            maxWidth: '300px',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: '800'
        },
        '&>label': {
            marginLeft: '10px',
            color: '#616161',
            fontSize: '0.9rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    body: {
        position: 'relative',
        background: '#f5f5f5',
        padding: '5px 15px',
        width: '100%',
        borderRadius: '7px',
        minHeight: '70px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)',
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_COMMENTS") {
        const comments = action.payload;
        const newComments = [];

        comments.forEach((comment) => {
            const commentIndex = state.findIndex((o) => o.id === comment.id);
            if (commentIndex !== -1) {
                state[commentIndex] = comment;
            } else {
                newComments.push(comment);
            }
        });

        return [...state, ...newComments];
    }

    if (action.type === "UPDATE_COMMENTS") {
        const comment = action.payload;
        const commentIndex = state.findIndex((o) => o.id === comment.id);
        if (commentIndex !== -1) {
            state[commentIndex] = comment;
            return [...state];
        } else {
            return [comment, ...state];
        }
    }

    if (action.type === "DELETE_COMMENTS") {
        const commentId = action.payload;

        const commentIndex = state.findIndex((o) => o.id === commentId);
        if (commentIndex !== -1) {
            state.splice(commentIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

export default function CommentTab({contactId}) {

    const [comments, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const { user } = useContext(AuthContext);
    const adminId = user.adminId;
    const classes = useStyles();

    useEffect(() => {
        socket.on(`customerRelationshipComment-${adminId}`, (data) => {

            if (data.action === "update" || data.action === "create") {
                // verificar se o comentário é do contato em questão
                if (data.comment?.contactId == contactId) dispatch({ type: "UPDATE_COMMENTS", payload: data.comment });
            }

            if (data.action === "delete") {
                dispatch({
                    type: "DELETE_COMMENTS",
                    payload: +data.commentId,
                });
            }
        });

        return () => { };
    }, []);

    useEffect(() => {
        (async () => {
            if (!contactId) return;
            setLoading(true);
            try {
                const { data } = await api.get(`/customerRelationship/comments/list/${contactId}`);
                dispatch({ type: "LOAD_COMMENTS", payload: data.comments });
                setLoading(false);
            } catch (err) {
                console.error({ err });
                toastError(err);
            }
        })();
    }, [contactId]);

    const handleStoreComment = async () => {
        try {
            setLoading(true);
            const { data } = await api.post("/customerRelationship/comments/create", {
                message: String(comment).trim(),
                messageType: 'conversation',
                contactId,
                isEdited: false,
                editedBy: null
            });
            toast.success("Comentário salvo com sucesso!");
            clearInputs();
        } catch (err) {
            console.error({ err });
            toastError(err);
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteComment = async (commentId) => {
        try {
            await api.delete(`/customerRelationship/comments/delete/${commentId}`);
            toast.success("Comentário deletado com sucesso!");
        } catch (err) {
            console.error({ err });
            toastError(err);
        }
    }

    const formatarData = (data) => {
        const meses = [
            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
            "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
        ];
    
        const dataObj = new Date(data);
    
        const dia = dataObj.getDate();
        const mes = meses[dataObj.getMonth()];
        const ano = dataObj.getFullYear();
        const horas = String(dataObj.getHours()).padStart(2, "0");
        const minutos = String(dataObj.getMinutes()).padStart(2, "0");
    
        return `${dia} de ${mes} de ${ano}, ${horas}:${minutos}`;
    }    

    const clearInputs = () => {
        setComment('');
    }

    return (
        <>
            <Grid 
                container
                spacing={5}
                style={{
                    background: '#ffffff',
                    position: 'sticky',
                    top: '0',
                    zIndex: '3',
                }}
            >
                <Grid
                    item
                    sm={12}
                    xs={12}
                    mb={2}
                >
                    <TextField 
                        id="outlined-basic" 
                        label="Comentário"
                        placeholder="Digite um comentário..."
                        variant="outlined"
                        fullWidth
                        multiline
                        inputProps={{
                            maxLength: 500
                        }}
                        InputLabelProps={{
                            style: {
                                padding: "0 10px",
                                background: "#ffffff",
                            },
                        }}
                        value={comment}
                        onChange={(e) => {setComment(e.target.value)}}
                    />
                    <IconButton
                        aria-label="sendComment-icon"
                        style={{
                            position: 'absolute',
                            right: '25px',
                            bottom: '55px'
                        }}
                        onClick={handleStoreComment}
                    >
                        <SendIcon />
                    </IconButton>
                    <Box 
                        component="div" 
                        style={{ marginTop: '10px', marginLeft: '2px' }}
                    >
                        {`${comment.length}/500`}
                    </Box>
                </Grid>
            </Grid>
            {
                comments.map(c => {
                    return (
                        <Grid container spacing={5}>
                            <Grid item sm={12} xs={12} mb={2} style={{ position: 'relative' }}>
                                <Box 
                                    component="div"
                                    className={classes.header}
                                >
                                    <Box
                                        component="span"
                                    >
                                        {GetShort(c.user?.name)}
                                    </Box>
                                    <Typography
                                        component="h6"
                                    >
                                        {c.user?.name}
                                    </Typography>
                                    <Typography
                                        component="label"
                                    >
                                        {formatarData(c.createdAt)}
                                    </Typography>
                                    <IconButton
                                        aria-label="delete-icon"
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '5px'
                                        }}
                                        onClick={() => {
                                            handleDeleteComment(c.id)
                                        }}
                                    >
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </Box>
                                <Box
                                    component="div"
                                    className={classes.body}
                                >
                                    {c.message}
                                </Box>
                            </Grid>
                        </Grid>
                    );
                })
            }
        </>
    );
}