import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Avatar, Collapse, CssBaseline, Divider, Popover, Typography, AppBar as MuiAppBar,
  Drawer as MuiDrawer, Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar
} from '@material-ui/core';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import React, { useContext, useState, useMemo } from 'react';
import { i18n } from '../../translate/i18n';
import clsx from 'clsx';
import { AuthContext } from '../../context/Auth/AuthContext';
import GetShort from '../../helpers/GetShort';
import { menuItems, adminItems, adminSuperItems } from './menuItems';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import Logo from "../../assets/logo.png";
import UserModal from '../UserModal';
import ChangePassword from '../User/changePassword';
import { useLocation } from 'react-router-dom';
import CheckIsActive from '../../helpers/CheckIsActive';
import classNames from 'classnames';
import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext';
import BackdropLoading from '../BackdropLoading';
import CheckSupportEmail from '../../helpers/CheckSupportEmail';
import ErrorIcon from '@material-ui/icons/Error';
import { useEffect, useRef } from 'react';
import CheckComponentAccess from '../../helpers/CheckComponentAccess';

const drawerWidth = 240;
const appBarHeight = '64px';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100vw'
  },
  appBar: {
    background: '#0b708c',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: appBarHeight
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    "& a": {
      color: "#000000",
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '73px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  profile: {
    height: "50px!important",
    width: "50px!important",
    textAlign: "center!important",
    paddingTop: "14px!important",
    borderRadius: "100%!important",
    position: "absolute!important",
    right: "20px!important",
    cursor: "pointer!important",
    textTransform: "uppercase!important",
    fontSize: "1.2rem!important",
    fontWeight: "800!important",
    "& span": {
      pointerEvents: "none"
    }
  },
  status: {
    height: "15px",
    width: "15px",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    borderRadius: "100%",
  },
  title: {
    color: "#ffffff",
    fontFamily: "Blouse, sans-serif",
    textShadow: "1px 1px #424242",
    fontSize: "1.4rem",
    paddingTop: "5px"
  },
  subtitle: {
    color: "#616161",
    fontSize: "0.8rem",
    fontFamily: "sans-serif",
    fontStyle: "italic",
    userSelect: "none",
    fontWeight: "800",
    width: "100%",
    textAlign: "center",
    bottom: "0px",
    padding: "5px",
    position: "relative",
    minHeight: "30px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  menuIcon: {
    width: "73px",
    "& svg": {
      margin: "0 auto",
      borderRadius: "100%",
      background: "#eeeeee",
      width: "35px",
      height: "35px",
      padding: "7px"
    }
  },
  menuActived: {
    "& div>svg": {
      background: "#0b708c",
      color: "#ffffff"
    }
  },
  warningIcon: {
    top: 0,
    background: "transparent!important",
    position: "absolute",
    color: "#b71c1c!important",
  },
  menuLink: {
    "&:hover": {
      background: 'rgba(0, 0, 0, 0.04)'
    }
  },
  submenuLink: {
    "&:hover": {
      background: '#e0e0e0'
    }
  }
}));

// ChildComponent isolado para memorizar os children
const ChildComponent = React.memo(({ children }) => {
  return <>{children}</>;
});

function LoggedLayout({ children }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState([]);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const { user, handleLogout, state, loading } = useContext(AuthContext);
  const customTheme = 'light';
  const history = useHistory();
  const { pathname } = useLocation();
  const { whatsApps } = useContext(WhatsAppsContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [itemsAdmin, setItemsAdmin] = useState(adminItems);
  const isMounted = useRef(false);

  const profileOpen = Boolean(profileAnchorEl);
  const id = profileOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    
    if (!CheckSupportEmail(user.email)) return;
    if (isMounted.current == true) return;
    isMounted.current = true;
  
    const idx = itemsAdmin.findIndex(
      (item) => item.text === i18n.t('menu.list.admin.title')
    );
  
    if (idx >= 0) {
      const updatedItems = [...itemsAdmin];
      updatedItems[idx] = {
        ...updatedItems[idx],
        items: [
          ...updatedItems[idx].items,
          {
            text: i18n.t('menu.list.admin.chatbot'),
            to: '/chatbot',
          },
        ],
      };
  
      setItemsAdmin(updatedItems);
    }
  }, []);  

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return ((
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          ) && whats.adminId == user.company.id);
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const theme = useMemo(() => createTheme({
    palette: {
      type: 'light',
      primary: {
        light: '#0B708C',
        main: '#0B708C',
        dark: '#0B708C',
        contrastText: '#ffffff',
      },
      background: {
        paper: '#ffffff',
      },
      text: {
        primary: '#000000',
      },
    },
    overrides: {
      MuiCheckbox: {
        root: {
          color: '#0B708C',
        },
        colorSecondary: {
          '&$checked': {
            color: '#0B708C',
          },
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: '#0B708C',
        },
      },
      MuiTab: {
        root: {
          '&$selected': {
            color: '#0B708C',
          },
        },
      },
    },
  }), []);

  const memorizedChildren = useMemo(() => {
    return <ChildComponent>{children}</ChildComponent>;
  }, [children]);

  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const handleDrawer = () => {
    // ao colapsar menu, fechar submenus
    if (open == true) setSubmenuOpen(submenuOpen.map(sb => { return { ...sb, open: false } }));
    setOpen(!open);
  };

  const handleClickLogout = () => {
    handleProfileClose();
    handleLogout(user?.id || '', user?.adminId || '');
  };

  const handleClick = (id) => {
    const submenus = submenuOpen.map((sb) =>
      sb.id === id ? { ...sb, open: !sb.open } : sb
    );
    if (!submenus.some(sb => sb.id === id)) {
      submenus.push({ id, open: true });
    }
    setSubmenuOpen(submenus);
  };

  // Filtrando itens do menu com base no perfil do usuário
  const filteredMenuItems = menuItems.filter(item => {
    if (user.profile === 'adminSuper') return false;
    if (user.profile === 'user') {
      // Se o perfil for 'user', não exibir alguns itens
      const hiddenItems = ['contacts', 'dashboard', 'relatory'];

      // Verifica se o item tem um nome e se deve ser ocultado
      if (item.name && hiddenItems.includes(item.name.toLowerCase())) {
        return false;
      }

      // Se o item tem subitens, filtra os subitens
      if (item.items) {
        item.items = item.items.filter(subItem => {
          if (subItem.name && hiddenItems.includes(subItem.name.toLowerCase())) {
            return false;
          }
          return true;
        });

        // Se não restarem subitens, não exibe o item pai
        if (item.items.length === 0) {
          return false;
        }
      }

      // Se o perfil for 'user', só exibir 'campaign' se campaignManagement for true
      if (item.name === 'campaign' && !user.campaignManagement) {
        return false; // Não exibir o item
      }
    }
    return true;
  });

  // Função para renderizar as opções do menu
  const renderMenuOptions = (menuItems) => {
    return menuItems.map((item, index) => {
      const id = `submenu-${index}`;
  
      // Verifica o acesso do atendente e da empresa
      if (
        item.to === '/customerRelationship' &&
        !CheckComponentAccess(user, 'customerRelationship')
      ) {
        return null;
      }
  
      // Renderiza submenu se houver itens aninhados
      if (Array.isArray(item?.items)) {
        const submenuIsOpen = submenuOpen.find((sb) => sb.id === id)?.open;
        const submenuClassName = classNames({
          [classes.menuLink]: true,
          [classes.menuActived]: item.items.some((subItem) =>
            CheckIsActive(pathname, subItem.to)
          ),
        });
  
        return (
          <Box key={id}>
            <Box style={{ background: submenuIsOpen ? '#eeeeee' : 'transparent' }}>
              <ListItem
                button
                style={{ paddingLeft: '0px' }}
                onClick={() => {
                  if (!open) handleDrawer();
                  handleClick(id);
                }}
                className={submenuClassName}
              >
                <ListItemIcon className={classes.menuIcon}>
                  {item.items.some((subItem) => subItem.to === '/connections') &&
                  connectionWarning ? (
                    <>
                      <ErrorIcon
                        className={classes.warningIcon}
                        style={{ left: '35px', top: '-5px' }}
                      />
                      {item.icon}
                    </>
                  ) : (
                    item.icon
                  )}
                </ListItemIcon>
                <ListItemText primary={item.text} />
                {submenuIsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
  
              <Collapse in={submenuIsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.items.map((subItem, subIndex) => (
                    <ListItem
                      key={`submenu-${subIndex}`}
                      style={{ paddingLeft: '30px' }}
                      component="a"
                      href={subItem.to}
                      onClick={(e) => handleMenuClick(e, subItem.to)}
                      className={classNames(classes.submenuLink)}
                    >
                      {subItem.to === '/connections' && connectionWarning ? (
                        <ListItemText
                          primary={
                            <>
                              {subItem.text}
                              <ErrorIcon className={classes.warningIcon} />
                            </>
                          }
                        />
                      ) : (
                        <ListItemText primary={subItem.text} />
                      )}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Box>
          </Box>
        );
      }
  
      // Renderiza item do menu principal
      return (
        <ListItem
          key={id}
          style={{ paddingLeft: '0px' }}
          component="a"
          href={item.to}
          onClick={(e) => handleMenuClick(e, item.to)}
          className={classNames({
            [classes.menuLink]: true,
            [classes.menuActived]: CheckIsActive(pathname, item.to),
          })}
        >
          <ListItemIcon className={classes.menuIcon}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      );
    });
  };
  
  // Função auxiliar para tratar cliques no menu
  const handleMenuClick = (e, to) => {
    e.preventDefault();
    if (e.ctrlKey || e.metaKey) {
      window.open(to, '_blank');
    } else {
      history.push(to);
    }
    if (open) handleDrawer();
  };
  
  if (loading) { return <BackdropLoading />; }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className={classes.root}>
        {/* Menu superior */}
        <MuiAppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
          <Toolbar>
            {/* botão para menu lateral */}
            <IconButton color="inherit" onClick={handleDrawer} edge="start">
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>

            {/* nome da aplicação */}
            <Typography variant="h6" className={classes.title}>
              {process.env.REACT_APP_NAME || 'Application Name'}
            </Typography>

            {/* perfil */}
            <Box
              onClick={handleProfileClick}
              className={classes.profile}
              style={{
                background: customTheme === 'dark' ? '#424242' : '#e0e0e0',
                color: customTheme === 'dark' ? '#ffffff' : '#424242',
              }}
            >
              <Box
                className={classes.status}
                style={{
                  background: state === 'online' ? 'green' : state === 'out' ? 'orange' : '',
                }}
              />
              {user?.profilePicture ? (
                <Avatar>profile picture</Avatar>
              ) : (
                <Typography component="span">{GetShort(user?.name || 'username')}</Typography>
              )}
            </Box>
            <Popover
              id={id}
              open={profileOpen}
              anchorEl={profileAnchorEl}
              onClose={handleProfileClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <List style={{ textTransform: 'capitalize' }}>
                <ListItem><ListItemText primary={user?.name} /></ListItem>
                <ListItem><ListItemText style={{ textTransform: 'lowercase' }} primary={user?.email} /></ListItem>
                <Divider style={{ background: '#bdbdbd' }} />
                {user?.profile === 'admin' && (
                  <ListItem button><ListItemText primary={i18n.t('menu.profile.changeProfile')} onClick={() => setUserModalOpen(true)} /></ListItem>
                )}
                <ListItem button><ListItemText primary={i18n.t('menu.profile.changePassword')} onClick={() => setChangeModalOpen(true)} /></ListItem>
                <ListItem button onClick={handleClickLogout}><ListItemText primary={i18n.t('menu.profile.logout')} /></ListItem>
              </List>
            </Popover>

          </Toolbar>
        </MuiAppBar>

        {/* Menu lateral */}
        <MuiDrawer
          variant="permanent"
          className={clsx(classes.drawer, { [classes.drawerOpen]: open, [classes.drawerClose]: !open })}
          classes={{ paper: clsx({ [classes.drawerOpen]: open, [classes.drawerClose]: !open }) }}
        >
          <ListItem
            style={{ height: appBarHeight, position: "relative" }}
          >
            <img
              src={Logo}
              style={{
                height: "100%",
                width: "auto",
                margin: "0 auto"
              }}
            />
          </ListItem>
          <Divider />
          <List>{renderMenuOptions(filteredMenuItems)}</List>
          {user?.profile === 'admin' && (
            <>
              <Divider />
              <List>{renderMenuOptions(itemsAdmin)}</List>
            </>
          )}
          {user?.profile === 'adminSuper' && (
            <>
              <Divider />
              <List>{renderMenuOptions(adminSuperItems)}</List>
            </>
          )}
          <Typography className={classes.subtitle}>{process.env.REACT_APP_VERSION_SYSTEM || 'Application version'}</Typography>
        </MuiDrawer>

        {/* Conteúdo */}
        <Box component="main" style={{ flex: 1, marginTop: appBarHeight, overflowX: "auto" }}>
          <div className={classes.appBarSpacer} />
          {memorizedChildren}
        </Box>
      </Box>

      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user.id}
      />
      <ChangePassword
        open={changeModalOpen}
        onClose={() => setChangeModalOpen(false)}
        userId={user.id}
      />
    </ThemeProvider>
  );
}

export default React.memo(LoggedLayout);