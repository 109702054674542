import { Button, Grid, Table, TableCell, TableHead, TableRow, TableBody, Switch, IconButton } from "@material-ui/core";
import { useState } from "react";
import { i18n } from "../../../translate/i18n";
import { useReducer, useEffect, useContext } from "react";
import socket from "../../../hooks/useSocket";
import { AuthContext } from "../../../context/Auth/AuthContext";
import toastError from "../../../errors/toastError";
import api from "../../../services/api";
import { toast } from "react-toastify";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from "../../ConfirmationModal";
import ProductAndServiceStoreModal from "../Modals/ProductAndServiceStoreModal";
import TableRowSkeleton from "../../TableRowSkeleton";

const reducer = (state, action) => {
    if (action.type === "LOAD_PRODUCTSANDSERVICES") {
        const productsAndServices = action.payload;
        const newProductsAndServices = [];

        productsAndServices.forEach((productAndService) => {
            const productsAndServicesIndex = state.findIndex((ps) => ps.id === productAndService.id);
            if (productsAndServicesIndex !== -1) {
                state[productsAndServicesIndex] = productAndService;
            } else {
                newProductsAndServices.push(productAndService);
            }
        });

        return [...state, ...newProductsAndServices];
    }

    if (action.type === "UPDATE_PRODUCTSANDSERVICES") {
        const productAndService = action.payload;
        const productsAndServicesIndex = state.findIndex((ps) => ps.id === productAndService.id);
        if (productsAndServicesIndex !== -1) {
            state[productsAndServicesIndex] = productAndService;
            return [...state];
        } else {
            return [productAndService, ...state];
        }
    }

    if (action.type === "DELETE_PRODUCTSANDSERVICES") {
        const productAndServiceId = action.payload;

        const productsAndServicesIndex = state.findIndex((ps) => ps.id === productAndServiceId);
        if (productsAndServicesIndex !== -1) {
            state.splice(productsAndServicesIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

export default function ProductAndServiceTab({ data, setData }) {

    const [productAndServiceModal, setProductAndServiceModal] = useState(false);
    const [productsAndServices, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const [selectedProductAndService, setSelectedProductAndService] = useState(null);
    const adminId = user.adminId;
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    useEffect(() => {
        socket.on(`customerRelationshipProductAndService-${adminId}`, (data) => {

            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_PRODUCTSANDSERVICES", payload: data.productAndService });
            }

            if (data.action === "delete") {
                dispatch({
                    type: "DELETE_PRODUCTSANDSERVICES",
                    payload: +data.productAndServiceId,
                });
            }
        });

        return () => { };
    }, []);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTags = async () => {
                try {
                    const { data } = await api.get("/customerRelationship/productAndService/list");
                    dispatch({ type: "LOAD_PRODUCTSANDSERVICES", payload: data.productsAndServices });
                    setLoading(false);
                } catch (err) {
                    console.error({ err });
                    toastError(err);
                }
            };
            fetchTags();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);


    const handleOriginModalClose = () => {
        setSelectedProductAndService(null);
        setProductAndServiceModal(false);
    }

    const handleDisable = async (originData) => {
        try {
            setLoading(true);
            originData = { ...originData, status: !originData.status };
            await api.put(`/customerRelationship/productAndService/update/${originData.id}`, originData);
            toast.success(i18n.t('backendSuccess.SAVED_SUCCESSFULLY'));
            setLoading(false);
        } catch (err) {
            console.error({ err });
            toastError(err);
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await api.delete(`/customerRelationship/productAndService/delete/${selectedProductAndService.id}`);
            toast.success(i18n.t('backendSuccess.DELETED_SUCCESSFULLY'));
            setLoading(false);
        } catch (err) {
            console.error({ err });
            toastError(err);
            setLoading(false);
        }
    }

    const handleConfirmModalClose = () => {
        setSelectedProductAndService(null);
        setConfirmModalOpen(false);
    }

    return (
        <>
            <Grid container spacing={5}>
                <Grid item sm={12} xs={12} mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: 'right' }}
                        onClick={() => { setProductAndServiceModal(true) }}
                    >
                        {i18n.t('buttons.add')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={5}>
                <Grid item sm={12} xs={12} mb={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{i18n.t("customerRelationship.configuration.productAndService.table.name")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.productAndService.table.status")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.productAndService.table.editedBy")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.productAndService.table.actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading == true
                                    ?
                                    <TableRowSkeleton columns={4} />
                                    :
                                    productsAndServices.map((productAndService) => (
                                        <TableRow>
                                            <TableCell
                                                productAndService="th"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                            >{productAndService?.name || ''}</TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={productAndService.status}
                                                    onChange={() => handleDisable(productAndService)}
                                                    color="primary"
                                                    name={`status${productAndService.status}`}
                                                    key={`status${productAndService.status}`}
                                                    inputProps={{ 'aria-label': 'handle status' }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                            >
                                                {productAndService.user?.name || ''}
                                            </TableCell>
                                            <TableCell
                                                style={{ display: "flex" }}
                                            >
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => {
                                                        setSelectedProductAndService(productAndService);
                                                        setProductAndServiceModal(true);
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="secondary"
                                                    component="span"
                                                    onClick={() => {
                                                        setSelectedProductAndService(productAndService);
                                                        setConfirmModalOpen(true);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <ProductAndServiceStoreModal
                open={productAndServiceModal}
                onClose={handleOriginModalClose}
                productAndServiceId={selectedProductAndService?.id || undefined}
            />
            <ConfirmationModal
                title={i18n.t("customerRelationship.configuration.productAndService.delete.title")}
                open={confirmModalOpen}
                onClose={handleConfirmModalClose}
                onConfirm={handleDelete}
            >
                {i18n.t("customerRelationship.configuration.productAndService.delete.description")}
            </ConfirmationModal>
        </>
    );
}